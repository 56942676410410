window._ = require('lodash');


/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
	window.Popper = require('popper.js').default;
	window.$ = window.jQuery = require('jquery');

	require('bootstrap');
	require('password-strength-meter')

} catch (e) {
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.xhrObj = null

$(function () {
	$('#resetPasswordForm #password').password({
		enterPass: 'Wpisz nowe hasło',
		shortPass: 'Podane hasło jest za krótkie',
		containsField: 'Hasło nie może być takie jak login',
		steps: {
			26: 'Bardzo niebezpieczne hasło',
			33: 'Słabe; spróbuj użyć liczb i znaków',
			67: 'Średnie; spróbuj użyć znaków specjalnych',
			94: 'Bardzo silne hasło',
		},
		showText: true,
		animate: true,
		animateSpeed: 'fast',
		field: '#email',
		fieldPartialMatch: false,
		useColorBarsImage: false,
		minimumLength: 8,
		closestSelector: '.input-group',
		customColorBarRGB: {
			red: [0, 240],
			green: [0, 240],
			blue: 10,
		}
	});
})

